<template>
  <validation-observer ref="form">
        <b-alert v-if="alert.show" show fade class="mb-2" variant="danger">
            <div class="custom-alert">
              <span>
                <BIconExclamationCircle />

                <template v-if="typeof alert.message === 'string'">
                  {{ alert.message }}
                </template>
                <template v-else>
                  <span v-for="(value, key) in alert.message" :key="key">
                    {{ value[0] }}
                  </span>
                </template>
              </span>
            </div>
          </b-alert>
          <b-row class="text-left">
            <b-col lg="12">
              <b-form-group label="Em quantos estados sua empresa está presente?" label-for="Estado">
              <v-select 
                  id="select-form"
                  v-model="national"
                  :options="formSelect"
                  label="text"
                  value="value"
                  placeholder="Selecione a quantidade de estados"
                  :clearable="false"
              >
              <span slot="no-options">Nenhuma opção selecionável.</span>
              </v-select>
              </b-form-group>
            </b-col>
          </b-row>
      <b-row v-if="national && national.value">
        <b-col class="national-card mx-1 p-1 d-flex flex-column mb-1">
          <span>
            Clique no ícone para entrar em contato pelo WhatsApp:
          </span><br>
          <a href="https://wa.me/5561996288309" target="_blank" class="d-flex justify-content-center">
            <img :src="whatsapp"/>
          </a>
          <a href="https://wa.me/5561996288309" target="_blank" class="text-custom-blue text-center mt-2 h2">
          (61) 99628-8309
          </a>
        </b-col>
        <b-col class="national-card mx-1 p-1 d-flex flex-column mb-1">
          <span class="mb-1">
            Aponte seu celular para o QR Code e entre em contato:
          </span>
          <div class="d-flex justify-content-center">
            <img :src="qrCode"/>
          </div>
        </b-col>
      </b-row>
    <b-form id="form" v-if="national && national.value == false">
      <b-row
        class="text-left"
      >
        <b-col md="6" xl="6">
          <!-- nome -->
          <b-form-group label="Nome*" label-for="nome">
            <validation-provider
              #default="{ errors }"
              name="nome"
              rules="required"
            >
              <b-form-input
                id="nome"
                placeholder="Seu nome completo"
                v-model="nome"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6" xl="6">
          <!-- email -->
          <b-form-group label="E-mail*" label-for="email">
            <validation-provider
              #default="{ errors }"
              name="e-mail"
              rules="required"
            >
              <b-form-input
                id="email"
                placeholder="Seu e-mail"
                v-model="email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" xl="6">
          <b-form-group label="Telefone*" label-for="telefone">
            <validation-provider
                #default="{ errors }"
                name="telefone"
                rules="required"
              >
            <vue-cleave
                id="telefone"
                v-model="telefone"
                :raw="false"
                :options="mascaraTelefone"
                class="form-control"
                placeholder="(99) 99999-9999"
                name="Telefone"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" xl="6">
          <!-- empresa -->
          <b-form-group label="Empresa*" label-for="empresa">
            <validation-provider
                #default="{ errors }"
                name="empresa"
                rules="required"
              >
            <b-form-input
              id="empresa"
              placeholder="Nome da empresa"
              v-model="empresa"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="text-left d-flex align-items-end">
        <b-col xl="6" lg="6" md="6" sm="12"  class=" mx-auto">
          <!-- uf -->
          <b-form-group label="Estado*" label-for="Estado">
            <validation-provider
              #default="{ errors }"
              name="estado"
              rules="required"
            >
              <v-select
                v-model="selected"
                :options="uf"
                label="uf"
                placeholder="Selecione uma opção"
                :clearable="false"
                :state="errors.length > 0 ? false : null"
              >
            </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" xl="6">
          <b-form-group label="Para quantas pessoas deseja cotar?*" label-for="numero">
            <validation-provider
              #default="{ errors }"
              name="quantidade"
              rules="required"
            >
              <b-form-input
                id="numero"
                v-model="numeroDose"
                placeholder="Informe a quantidade de pessoas"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row class="text-left">
        <b-col class="col-12 mx-auto">
          <!-- mensagem -->
          <b-form-group label="Mensagem *" label-for="mensagem">
            <validation-provider
              #default="{ errors }"
              name="mensagem"
              rules="required"
            >
              <b-form-textarea
                id="mensagem"
                placeholder="Nos dê mais informações sobre como podemos ajudá-lo"
                v-model="mensagem"
                :state="errors.length > 0 ? false : null"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col class="col-12 mb-1 text-center">
          <b-button
            type="submit"
            variant="custom-blue"
            :disabled="bloquearEnvio"
            @click.prevent="enviarFormulario"
          >
            Enviar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div id="modal-formulario"></div>
  </validation-observer>
</template>

<script>
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BFormTextarea,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BAlert
} from "bootstrap-vue";
import VueCleave from "vue-cleave-component";
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from 'vue-select'
import qrCode from '@/assets/custom-images/pages/qrcode.png';
import whatsapp from '@/assets/custom-images/pages/whatsapp.png';

export default {
  components: {
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    VueCleave,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormSelect,
    BFormSelectOption,
    BAlert,
    vSelect
  },
  data() {
    return {
      qrCode,
      whatsapp,
      alert: {
        show: false,
        message: '',
        variant: ''
      },
      required,
      mascaraTelefone: {
        delimiters: ['(', ') ', '-'],
        blocks: [0, 2, 5, 4],
        numericOnly: true
      },
      tipoDeContato: "Financeiro",
      nome: null,
      email: null,
      empresa: null,
      assunto: null,
      mensagem: null,
      telefone: null,
      numeroDose: null,
      estados: null,
      uf: [],
      national: null,
      formSelect: [
        {
          value: true,
          text: 'Em dois ou mais estados'
        },
        {
          value: false,
          text: 'Em um único estado'
        }
      ],
      selected: null
    };
  },
  async created() {
    await this.$http.get(this.$api.getEstados()).then(({ data }) => {
      this.uf = data;
    });
  },
  methods: {
    limpaFormulario() {
        this.nome = "";
        this.email = "";
        this.empresa = "";
        this.telefone = "";
        this.numeroDose = "";
        this.estados = "";
      this.nacional = false;

      this.assunto = "";
      this.mensagem = "";
      this.selected = null;

      this.$refs.form.reset();
    },
    mostrarModal(sucesso) {
      let title = sucesso
        ? "Mensagem enviada com sucesso"
        : "Mensagem não enviada";
      let text = sucesso
        ? "Entraremos em contato em breve"
        : "Tente novamente mais tarde.";

      this.limpaFormulario();

      this.$swal({
        title: `<h3>${title}</h3>`,
        html:`<p class="form-text">${text}</p>`,
        icon: sucesso ? "success" : "error",
        iconColor: sucesso ? "#36ba00" : "red",
        target: "#modal-formulario",
        customClass: {
          text: "form-text",
          confirmButton: "btn btn-custom-blue",
        },
        buttonsStyling: false,
      });
    },
    errorHandling(response) {
      let message = response.data ? response.data.errors : response;      
      
      this.showAlert(
          message,
          'danger'
      );
    },
    showAlert(message, variant) {
      this.alert.show = true;
      this.alert.message = message;
      this.alert.variant = variant;
    },
    enviarFormulario() {

      this.$refs.form.validate().then((success) => {
        if (success) {
          let dados = {
            tipo_de_contato: this.tipoDeContato,
            nome: this.nome,
            email: this.email,
            telefone: this.telefone,
            empresa: this.empresa,
            numeroDose: this.numeroDose,
            assunto: this.tipoDeContato,
            mensagem: this.mensagem,
            uf: this.selected.uf
          };

          this.$http
            .post(this.$api.ajudaSendEmail(), dados)
            .then(() => {
              this.mostrarModal(true);
              this.alert.show = false
            })
            .catch(error => {
              if(error.response){
                this.errorHandling(error.response);
              }else{
                this.errorHandling("Tempo de espera expirado. Tente novamente ou contate o suporte.");
              }
            })
        }
      });
    },
  },
  computed: {
    bloquearEnvio: function () {
      return !this.nome;
    },
  }
};
</script>

<style>
#form textarea#mensagem {
  height: 190px;
}
#form .btn.btn-custom {
  width: 138px;
}

.form-text {
  margin: 0!important;
  font-size: 14px!important;
  font-weight: 400!important;
}

div#swal2-content {
    margin: 0;
}

.national-card {
  border-radius: 6px;
  background: var(--2-opacity-color-primary-6, rgba(39, 114, 192, 0.06));
}
</style>
